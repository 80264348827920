import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { filter, map, switchMap } from 'rxjs/operators';

import { environment } from '@environment';
import { APP_NAMES } from '@shared/constants/app-names.constants';
import { DataService, DSHttpError } from '@shared/services/data-service';
import { DocumentService } from '@shared/services/document/document.service';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'uc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  environment = environment;
  showFlashMessages = false;
  systemMessageSet = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private flashService: FlashMessageService,
    private dataService: DataService,
    private documentService: DocumentService,
    private userService: UserService,
    private gtmService: GoogleTagManagerService,
  ) {}

  // eslint-disable-next-line class-methods-use-this
  get envInitials() {
    return environment.envName.slice(0, 2);
  }

  ngOnInit() {
    // dynamic titles, see the data defined in routes for specifying titles
    // https://toddmotto.com/dynamic-page-titles-angular-2-router-events
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            // eslint-disable-next-line no-param-reassign
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
      )
      .subscribe((event) => {
        window.scrollTo(0, 0);
        this.titleService.setTitle(event.title ? event.title : 'Application to Enrol | UC');
        this.showFlashMessages = true;

        if (this.gtmService.googleTagManagerId) {
          // GTM / Google Tags
          const gtmTag = {
            event: 'page',
            page_path: window.location.pathname,
            page_title: event.title,
          };
          // SPA apps need to tell G.A. about page views
          this.gtmService.pushTag(gtmTag);
        }
      });

    this.flashService.messages
      .pipe(map((msg) => msg.filter((m) => m.type === 'system')))
      .subscribe((systemMessages) => (this.systemMessageSet = !!systemMessages.length));

    if (environment.systemMessage?.[APP_NAMES.student]) {
      this.flashService.pushSystem(environment.systemMessage.myUC);
    }

    this.userService.userDetail.pipe(filter((user) => !!user)).subscribe(() => {
      this.documentService.getImportantDocuments();
      this.documentService.checkForUnreadDocuments();
    });

    if (environment.features.beepBoop) {
      setTimeout(() => {
        const err = new DSHttpError(new HttpErrorResponse({ status: 422, statusText: 'Beep boop test' }), {
          code: 'testing.generic',
        });
        this.dataService.allErrors$.next(err);
      }, 10000);
    }

    if (!environment.features.libraryH3lp) {
      // if the chat button isn't on the page after 20s...
      // we probably don't want a recursive script running forever
      const maxLoadTime = 20000;
      const retryTime = 200;
      let loadTime = 0;
      const tryToDisableChat = () => {
        const chatOpeners: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('.libraryh3lp a');
        if (!chatOpeners.length && loadTime < maxLoadTime) {
          setTimeout(tryToDisableChat, retryTime);
          loadTime += retryTime;
          return;
        }

        chatOpeners.forEach((chatOpener) => {
          chatOpener.href = '';
          chatOpener.onclick = (e) => {
            window.alert('Chat disabled');
            e.preventDefault();
            e.stopPropagation();
            return false;
          };
        });
      };

      tryToDisableChat();
    }
  }
}
